 /deep/ .el-dialog .el-table th {
  background: #5c6be8;
  color: #ffffff;
  border-color: #5c6be8;
}
.upload-workers {
  height: 40px;
}
.upload-workers /deep/ .el-upload {
  height: 40px !important;
  width: 97px;
  border: none !important;
  float: left;
}
.upload-workers /deep/ .el-upload .el-button {
  padding: 5px 10px;
}
.upload-workers /deep/ .el-upload-list {
  display: flex;
  flex-wrap: wrap;
  /* 换行 */
}
.upload-workers /deep/ .el-upload-list li {
  width: 20%;
}
.fle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
