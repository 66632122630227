


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/deep/ .el-dialog .el-table th{
  background: rgb(92, 107, 232);
  color: rgb(255, 255, 255);
  border-color: rgb(92, 107, 232);
}
.upload-workers {
  height: 40px;
  /deep/ .el-upload {
    height: 40px !important;
    width: 97px;
    border: none !important;  
    float: left;
    .el-button {
      padding: 5px 10px;
    }

  }
  /deep/ .el-upload-list {
      display: flex;
      flex-wrap: wrap;   /* 换行 */
      li {
        width: 20%;
      }
    }
}
.fle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
